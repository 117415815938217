
    
(function($){
      
  $(window).load(function() {
    //SVG Detection...
    if(SVGsupport()){
      $('#body-wrapper').addClass('svg');
    }
      
    
    function setAgreeCookie() {
	console.log(42)
    $.cookie("popup","clicked",{expires:7,path:"/"});
	}
	
	console.log($.cookie('popup'));
	
	if ($.cookie('popup') === null) {
		jQuery("#bookdirectp").show();
	}
    
    
     jQuery("#bookdirectlink").click(function() {
            jQuery("#bookdirectp").show();
        });
        
	jQuery(".close").click(function() {
		setAgreeCookie();
		jQuery("#bookdirectp").hide();
	});
		
		
		

    $('.js-hidden').fadeOut().removeClass('js-hidden').fadeIn();
    
    $('.cookie-message').cookieBar({ closeButton : '.my-close-button' });
    $('.cookie-message').on('cookieBar-close', function() { $('.nav-bar__book').removeClass('with-bar'); })
    
    setTimeout(function(){
	    if(!$('.cookie-message').is(":visible")){
	    	$('.nav-bar__book').removeClass('with-bar');
	    }
	  }, 200);
	    
    $('#accordion1').accordiom({
	showFirstItem: false, // Either show or hide the first item when loading
	});
	
	$('.gallery__carousel').slick({
		arrows: true,
		infinite: true,
		slidesToShow: 1,
		centerMode: true,
		variableWidth: true
	});

    var width = $(window).width();
    if ((width >= 775)) {

      //Enable MatchHeight on larger screens
      $('.matchHeight').matchHeight({
        byRow: false,
        property: 'height',
        target: null,
        remove: false
      });

      //Enable animations on larger screens
      new WOW().init();
    }   
    
   


      $('#hero__bwpad').pickadate({
        min: new Date(),
        format: 'dd/mm/yyyy'
      });
     
    


     $('.popup-link').click(function(event) {
      event.preventDefault();
      var target = $(this).data('popup');
      $('.popups').addClass('active');
      $('.popup-box.'+ target).addClass('active');
      $('body').addClass('no-scroll');
    });

    $('.close-button').click(function() {
      $('.popup-box.active').removeClass('active');
      setTimeout( function(){ $('.popups').removeClass('active'); }, 350)
      $('body').removeClass('no-scroll');
    });





    //Scroller listenr to hide header 
    var shrinkHeader = 200; /*Defines how many pixels you scroll before the header shrinks*/    
    $(window).scroll(function() {
      var scroll = getCurrentScroll();
      if ( scroll >= shrinkHeader ) {
        $('body').addClass('scrolled');
      } else {
        $('body').removeClass('scrolled');
      }
    });





   






    //FB Share page JS
    $('.fbShare').click(function(event){
      event.preventDefault();
      FB.ui({
        method: 'share',
        href: window.location.href ,
      }, function(response){});
    })
    //FB Share page JS END








    var grSlick = $('#grSlick').slick({
        speed: 300,
        dots: false,
        arrow: true,
        //slidesToShow: 1,
        centerMode: true,
        centerPadding: '10px',
        variableWidth: true
      });

    $('.gallery-row__slide').click(function(){
     
       var currentSlide = $('.slick-current').data('slick-index');
       var nextSlide = $(this).data('slick-index');

       if(nextSlide > currentSlide){
         console.log('Forward a slide!');
         grSlick.slick('slickNext');
       }else if(nextSlide < currentSlide){
         console.log('Backwards a slide...');
         grSlick.slick('slickPrev');
       }
    })


    var ghSlick = $('#ghSlick').slick({
        speed: 300,
        dots: false,
        arrow: true,
        //slidesToShow: 1,
        centerMode: true,
        centerPadding: '0px',
        variableWidth: true
      });


    var pSlick = $('#pSlick').slick({
        speed: 300,
        dots: false,
        arrow: true,
        //slidesToShow: 1,
        centerMode: true,
        centerPadding: '10px',
        variableWidth: true
      });








    //Booking widget form submission/redirection
    $('#nav-booking-bar').submit(function(){
      event.preventDefault();

      var checkInRaw = $('#hero__bwpad').val();
      var checkIn = checkInRaw.split('/');
      
      var year = checkIn[2];
      var month = checkIn[1];
      var day = checkIn[0];
      

      var nights  = $('#nights').val();


      var bookingURL = "http://fe.avvio.com/convert/site/Norfolk%20Towers%20Paddington/en/results.php?checkin="+year+"-"+month+"-"+day+"&nights="+nights+"&currency=GBP";

      window.location.href = bookingURL;
    });


    $('#popupForm').submit(function(){
      event.preventDefault();

      var checkInRaw = $('#popupDate').val();
      var checkIn = checkInRaw.split('/');
      
      var year = checkIn[2];
      var month = checkIn[1];
      var day = checkIn[0];
      

      var nights  = $('#popupNights').val();


      var bookingURL = "http://fe.avvio.com/convert/site/Blakemore%20Hyde%20Park/en/results.php?checkin="+year+"-"+month+"-"+day+"&nights="+nights+"&currency=GBP";

      window.location.href = bookingURL;
    });





    $('.js__openBooking').click(function(){
        event.preventDefault();
        $('.popup__booking-widget').toggleClass('active');
    });
    $('.popup__booking-widget .popup-close').click(function(){
      $('.popup__booking-widget').toggleClass('active');
    })

    $('.mobile-nav-popup__open').click(function(){
      $('.mobile-nav-popup').toggleClass('active');
      $('body').addClass('no-scroll');
    })
    $('.mobile-nav-popup__close').click(function(){
      $('.mobile-nav-popup').toggleClass('active');
      $('body').removeClass('no-scroll');
    })

    




  // Scroll to top btn
    $('.to-the-top').click(function(){
      $('html, body').animate({scrollTop : 0},800);
      return false;
    });


    // make scroll down btn appear
    var waypoint = new Waypoint({
      element: document.getElementById('show-menu-waypoint'),
      handler: function() {
        
      }
    })

    // hide/show scroll down btn
    var waypoint = new Waypoint({
      element: document.getElementById('show-menu-waypoint'),
      handler: function(direction) {
        //console.log(direction);
        if(direction == 'up') {
          $('.to-the-top').fadeOut();   
        } else {}
        if(direction == 'down') {
          $('.to-the-top').fadeIn();  
        } else {}
      }
    })
 
    var homeSlick = $('#homeSlick').slick({
      speed: 300,
      dots: false,
      arrow: false,
      //slidesToShow: 1,
      centerMode: true,
      centerPadding: '0px',
      variableWidth: true,
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: false
    });






  });
  
  


 

  
  function getCurrentScroll() {
    return window.pageYOffset || document.documentElement.scrollTop;
  }

  function SVGsupport() {
    return document.implementation.hasFeature("http://www.w3.org/TR/SVG11/feature#Image", "1.1");
  }

})(jQuery);